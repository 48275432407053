@import url("https://fonts.googleapis.com/css2?family=Exo:wght@700&family=Montserrat:wght@500&family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
.app {
  height: 100%;
  /* background-color: #010026; */
  /* background: rgb(9,121,76);
  background: linear-gradient(90deg, rgba(9,121,76,1) 4%, rgba(4,7,17,1) 33%, rgba(14,40,29,1) 100%); */
  /* background: rgb(9,121,76);
  background: linear-gradient(90deg, rgba(9,121,76,1) 0%, rgba(61,48,119,1) 28%, rgba(10,11,28,1) 58%, rgba(14,40,29,1) 100%); */
  /* background: rgb(9,121,76);
  background: linear-gradient(90deg, rgba(9,121,76,1) 0%, rgba(61,48,119,1) 13%, rgba(14,40,29,1) 21%, rgba(10,11,28,0.97093837535014) 100%); */
  /* background: rgb(25,60,46);
  background: linear-gradient(90deg, rgba(25,60,46,1) 4%, rgba(48,39,88,0.9821428571428571) 9%, rgba(10,11,28,1) 23%); */
  /* background-color: #1a1c1e;
  background-image: linear-gradient(160deg, #1a1c1e 10%, #112a1a 90%);   */

  background-color: #1a1c1e;
  background-image: linear-gradient(
    119deg,
    #1a1c1e 64%,
    #27603a 88%,
    #765ce5 100%
  );

  @apply text-white;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}